import { useQuery } from '@tanstack/react-query';
import { ListItem, ListPlaceholder } from './DashboardList';
import useAxios from "../utils/useAxios";
import { ListSpinner } from './LoadingSpinner';
import { useState, useEffect, useCallback } from 'react';
import DialogOverlay from './DialogOverlay';

import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(duration);
dayjs.extend(relativeTime);

// Constants
const RESERVATIONS_ENDPOINT = `/reservations-user/`;

// Fetch reservations
async function fetchReservations(api) {
  try {
    const { data: response } = await api.get(RESERVATIONS_ENDPOINT);
    return response;
  } catch (error) {
    throw error; // throw the error to be caught by react-query
  }
}

// Reservation countdown hook
function useReservationCountdown(reservation) {
  const [countdown, setCountdown] = useState('');
  const [statusText, setStatusText] = useState('');
  const [statusTextClass, setStatusTextClass] = useState('');

  useEffect(() => {
    setStatusText('loading..');
    setStatusTextClass('text-black2');
    setCountdown(``);
    const countdownInterval = setInterval(() => {
      if (reservation) {
        
        const now = dayjs();
        const start = dayjs(reservation.starts_at);
        const end = dayjs(reservation.ends_at);

        if (now.isBefore(start)) {
          const diff = start.diff(now);
          setStatusText('Upcoming');
          setStatusTextClass('text-success');
          setCountdown(`Starts in ${dayjs.duration(diff).humanize()}`);
        } else if (now.isBefore(end)) {
          const diff = end.diff(now);
          setStatusText('On going');
          setStatusTextClass('text-primary2');
          setCountdown(`Ends in ${dayjs.duration(diff).humanize()}`);
        } else {
          setStatusText('Ended');
          setStatusTextClass('text-danger');
          setCountdown('');
        }
      }
    }, 1000);

    return () => clearInterval(countdownInterval);
  }, [reservation]);

  return { countdown, statusText, statusTextClass };
  
}

// List item with countdown
function ListItemWithCountdown({ reservation, onClick }) {
  const { countdown } = useReservationCountdown(reservation);
  const title = `${reservation.robot_name} | ${dayjs(reservation.starts_at).format("dddd DD.MM.")} ${countdown && '| ' + countdown}`;

  return (
    <ListItem key={reservation.id} title={title} onClick={onClick} disabled={false} />
  );
}

function Reservations() {
  const api = useAxios();
  const { data, status } = useQuery(['reservations'], () => fetchReservations(api));

  const [selectedReservation, setSelectedReservation] = useState(null);
  const { countdown: selectedCountdown, statusText, statusTextClass } = useReservationCountdown(selectedReservation);

  const [hidePast, setHidePast] = useState(true); // Add this line

  const handleOpenDialog = useCallback((reservation) => {
    setSelectedReservation({
      ...reservation,
      isLoading: true,
    });
  }, []);

  const handleCloseDialog = useCallback(() => {
    setSelectedReservation(null);
  }, []);

  useEffect(() => {
    if (selectedReservation && selectedReservation.isLoading) {
      setSelectedReservation({
        ...selectedReservation,
        isLoading: false,
      });
    }
  }, [selectedReservation]);

  if (status === 'loading') return <ListSpinner />;
  if (status === 'error') return <p>error!</p>;
  if (data.length === 0) return <ListPlaceholder text="Once you create a reservation, it will be displayed here." />;

  const now = dayjs();

  return (
    <>
      <button className=' absolute right-6 top-3 dark:text-primary2' onClick={() => setHidePast(!hidePast)}>{hidePast ? 'Show past' : 'Hide past'}</button>

      {(() => {
        const upcomingReservations = data.filter((obj) => !hidePast || now.isBefore(obj.ends_at));
        if (upcomingReservations.length === 0) {
          return <ListPlaceholder text={`You currently have no upcoming reservations. Once you create a new reservation, it will be displayed here.`} />
        }
        return upcomingReservations.map((obj) => (
          <ListItemWithCountdown key={obj.id} reservation={obj} onClick={() => handleOpenDialog(obj)} />
        ));
      })()}

      <DialogOverlay
        isLoading={selectedReservation ? selectedReservation.isLoading : false}
        isOpen={!!selectedReservation}
        onClose={handleCloseDialog}
        title="Reservation details"
        classes="max-w-sm"
        messages={!selectedReservation?.isLoading && [
          { text: `Status: ${statusText}`, classes: `mt-2 ${statusTextClass}` },
          { text: selectedCountdown },
          { text: `Robot / Equipment: ${selectedReservation?.robot_name}`, classes: 'mt-2' },
          { text: `Date: ${selectedReservation ? dayjs(selectedReservation.starts_at).format("dddd DD.MM.YYYY") : ''}` },
          { text: `Starts: ${selectedReservation ? dayjs(selectedReservation.starts_at).format("HH:mm") : ''}` },
          { text: `Ends: ${selectedReservation ? dayjs(selectedReservation.ends_at).format("HH:mm") : ''}` },
          { text: `Reservation ID: ${selectedReservation?.id}`, classes: 'mt-2' },
          { text: `Created: ${selectedReservation ? dayjs(selectedReservation.created_at).format("HH:mm:ss DD-MM-YYYY") : ''}` },
          { text: `Last modified: ${selectedReservation ? dayjs(selectedReservation.modified_at).format("HH:mm:ss DD-MM-YYYY") : ''}` },
          { text: `If you want to modify or cancel the reservation, please contact the Avoin Robolab staff with following reservation ID: "${selectedReservation?.id}".`, classes: 'text-sm mt-5' },
        ]}
        buttons={[
          { label: 'Close', onClick: handleCloseDialog }
        ]}
      />
    </>
  );
}

function ReservationList() {
  return <Reservations />
}

export default ReservationList;