import { Redirect, Route, useLocation } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../context/AuthContext";
import { toast } from 'react-toastify';

const PrivateRoute = ({ showError, children, ...rest }) => {

  const location = useLocation();

  const errors = {
    '/robot' : 'You have to login before you can book the robots.'
  }

  function handleLogin() {
    showError && toast.error(errors[location.pathname]);
    return <Redirect to="/login" />
  }
  let { user } = useContext(AuthContext);
  return <Route {...rest}>{!user ? handleLogin : children}</Route>;
};

export default PrivateRoute;