const Footer = () => {
  // return (
  //   <>
  //     <div className="h-10 block"></div>
  //     <div className="bg-slate-800 text-white bottom-0 fixed w-full p-2 text-center -m-4 -mb-0">
  //     <p className=' cursor-default transition text-center mt-5 text-black2 text-opacity-30 hover:text-opacity-100'>If you have any problems contact: <a className='font-bold hover:text-primary2' href="mailto:riku.manninen@hamk.fi">riku.manninen@hamk.fi</a></p>

  //     </div>
  //   </>
  // );
};

export default Footer;