/* eslint-disable import/no-anonymous-default-export */
function ListSpinner() {
  const classes = 'mt-5 drop-shadow-lg mb-5 p-5 rounded-xl text-lg font-bold text-transparent bg-white dark:bg-gray w-full block opacity-40 '
  return (
    <div className={classes}>Loading...
      <div className="animate-[spinner-grow_0.75s_linear_infinite] inline-block w-6 h-6 bg-current m-auto absolute top-0 bottom-0 left-0 right-0 rounded-full opacity-0 text-primary dark:text-primary2" role="status">
        <span className="visually-hidden hidden">Loading...</span>
      </div>
    </div>
  )
}

function Spinner({className}) {
  let classes = 'text-lg font-bold text-transparent bg-transparent w-full block opacity-40 absolute top-0 '
  classes += className
  return (
    <div className={classes}>Loading...
  <div className=" animate-[spinner-grow_0.75s_linear_infinite] scale-75 inline-block w-6 h-6 bg-current m-auto absolute top-0 bottom-0 left-0 right-0 rounded-full opacity-0 text-primary dark:text-primary2" role="status">
    <span className="visually-hidden hidden">Loading...</span>
  </div>
  </div>
  )
}

export { ListSpinner, Spinner }