const ListContainer = ({children}) => {
  return <div className="w-full px-6 block relative">{children}</div>
}

const ListItem = ({title, link, disabled, openModal, onClick}) => {
  return (
    <div className="mt-5 min-h-16 relative block w-full">
    {disabled && openModal && <button onClick={openModal} className="absolute z-10 top-0 bottom-0 right-0 left-0 m-auto transition-all text-transparent hover:text-primary dark:hover:text-primary2 font-bold">Click to activate</button>}

    <a
    onClick={onClick}
    href={link ? link : '#'}
    rel="noreferrer"
    disabled={disabled}
    className="dark:bg-gray dark:text-primary2 hover:outline-secondary dark:hover:outline-primary2 outline-transparent outline-offset-0 outline hover:outline-offset-4 hover:cursor-pointer outline-2 transition-all duration-75 border-white drop-shadow-lg mb-5 p-5 rounded-xl text-lg font-bold text-secondary bg-white w-full block disabled:opacity-40 disabled:outline-0"
    >
      {title}
    </a>
    </div>
    )
}

const ListTitle = ({title}) => {
  return <h4 className="w-full block mt-5 text-3xl font-extrabold text-secondary dark:text-primary2">{title}</h4>
}

const ListPlaceholder = ({text}) => {
 return <p className="mt-5 font-medium text- dark:text-primary2">{text}</p>
}

export {ListContainer, ListItem, ListTitle, ListPlaceholder}