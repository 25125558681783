import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Dialog, Transition } from '@headlessui/react';
import { Spinner } from './LoadingSpinner';

function DialogOverlay({ isLoading, isOpen, onClose, title, classes, messages, buttons, onSubmit }) {
console.log(isOpen)
  return (
    <>
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog open={isOpen} onClose={onClose} as="div" className="relative z-10">
        {/* BACKGROUND */}
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-[#101010] bg-opacity-50"/>
        </Transition.Child>

        {/* DIALOG PANEL */}
        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              {isLoading ? <div className={`w-full transform overflow-hidden rounded-2xl bg-white dark:bg-gray p-10 text-left align-middle shadow-xl transition-all ${classes}`}><Spinner className={'left-0 mt-6'}/></div> : 
              <div className={`w-full transform overflow-hidden rounded-2xl bg-white dark:bg-gray p-6 text-left align-middle shadow-xl transition-all ${classes}`}>
                <Dialog.Title as="h2" className="text-lg text-center font-medium leading-6 text-black dark:text-primary2">
                  {title}
                </Dialog.Title>
                  {messages && messages.map((message, index) => 
                <Dialog.Description key={index} as="p" className={`text-sm text-black2 text-center dark:text-light dark:text-opacity-50 ${message.classes}`}>
                  {message.text}
                </Dialog.Description>)}
                {onSubmit && 
                  <form type="POST" onSubmit={onSubmit}>
                    <input id="code" name='code' type="text" className=' w-40 text-sm font-medium text-center rounded-md mr-2 dark:text-light dark:focus:border-primary2 dark:focus:ring-transparent dark:bg-gray dark:focus:bg-gray-focus appearance-none rounded-b-md border px-3 py-2 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'></input>
                    <button
                      type="submit"
                      className="inline-flex justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white dark:bg-gray dark:active: dark:text-light dark:border-primary2 dark:hover:bg-gray-focus dark:focus:shadow-none dark:focus:ring-offset-0 dark:focus:ring-primary2 dark:focus:ring-opacity-50 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      //onClick={closeDialog}
                    >
                      Submit
                    </button>
                  </form>
                }
                <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  {buttons && buttons.map((button, index) => 
                    <button
                    key={index}
                    type="button"
                    className="inline-flex justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white dark:bg-gray dark:active: dark:text-light dark:border-primary2 dark:hover:bg-gray-focus dark:focus:shadow-none dark:focus:ring-offset-0 dark:focus:ring-primary2 dark:focus:ring-opacity-50 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={button.onClick}
                    >
                      {button.label}
                    </button>
                  )}
                </div>
              </div>
}
            </Transition.Child>
          </div>
        </div>
      </Dialog>
      </Transition>
  </>
  )
}

DialogOverlay.propTypes = {
  title: PropTypes.string,
  classes: PropTypes.string,
  messages: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    classes: PropTypes.string,
  })),
  buttons: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
  })),
};

DialogOverlay.defaultProps = {
  title: '',
  classes: '',
  messages: [],
  buttons: [],
};

export default DialogOverlay;