import React, { useEffect } from "react";
import "./index.css";
import Footer from "./components/Footer";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import PrivateRoute from "./utils/PrivateRoute";
import { AuthProvider } from "./context/AuthContext";
import Home from "./views/homePage";
import Login from "./views/loginPage";
import Register from "./views/registerPage";
import Robot from "./views/robotPage";
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { HelmetProvider } from 'react-helmet-async'
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DayjsUtils from '@date-io/dayjs';

const queryClient = new QueryClient()

function App() {

  useEffect(() => {
    document.body.classList.add('bg-light');
    document.body.classList.add('dark:bg-dark');
    return () => {
      document.body.style.backgroundColor = null;
    };
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <HelmetProvider>
      <MuiPickersUtilsProvider utils={DayjsUtils} locale="fi">
        <Router>
          <AuthProvider>
            <Switch>
              <Route component={Login} path="/login" />
              <PrivateRoute showError={true} component={Robot} path="/robot/:robotId" />
              <Route component={Register} path="/register" />
              <PrivateRoute component={Home} path="/" />
            </Switch>
          </AuthProvider>
          <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="dark"
            />
          <Footer />
        </Router>
        </MuiPickersUtilsProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </HelmetProvider>
    </QueryClientProvider>
  );
}

export default App;