import { useState, useMemo } from "react";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useMediaQuery } from '../utils/useMediaQuery';
import { Helmet } from "react-helmet-async"
import SwipeableViews from 'react-swipeable-views';
import Navbar from "../components/Navbar";
import OrientationList from "../components/OrientationList";
import RobotList from "../components/RobotList";
import ReservationsList from "../components/ReservationsList";
import WorkshopList from "../components/WorkshopList";
import { ListContainer, ListTitle } from "../components/DashboardList";
import { StyledTabs, StyledTab } from '../components/StyledComponents';



const Home = () => {
  const isMobile = useMediaQuery('(min-width: 640px)');

  const styles = {
    slideContainer: {
      maxWidth: isMobile ? '24rem' : '100%',
      width: '100%',
      flexShrink: 0,
      overflowX: 'hidden',
    },
    containerStyle: {
      overflowX: isMobile ? 'auto' : 'initial',
      height: '100%',
    },
  };

  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const theme = useMemo(
    () =>
      createTheme({
        palette: {
          mode: prefersDarkMode ? 'dark' : 'light',
        },
      }),
    [prefersDarkMode],
  );
  const [index, setIndex] = useState(0)

  const handleChange = (event, value) => {
    setIndex(value)
  };

  const handleChangeIndex = index => {
    setIndex(index)
  };

  return (
        <>
      <Helmet><title>Home | Avoin Robolab</title></Helmet>
      <ThemeProvider theme={theme}>
        <section className="h-[calc(100%-96px)] block relative">
          <Navbar />
          <SwipeableViews containerStyle={styles.containerStyle} slideStyle={styles.slideContainer} index={index} onChangeIndex={handleChangeIndex} className={`h-full block relative w-full`}>
            <ListContainer>
              <ListTitle title="Wonda" />
              <OrientationList />
            </ListContainer>
            <ListContainer>
              <ListTitle title="Robots and Equipment" />
              <RobotList />
            </ListContainer>
            <ListContainer>
              <ListTitle title="My Reservations" />
              <ReservationsList />
            </ListContainer>
            <ListContainer>
              <ListTitle title="Workshops" />
              <WorkshopList />
            </ListContainer>
          </SwipeableViews>
          <StyledTabs
            value={index}
            onChange={handleChange}
            variant="fullWidth"
            className="shadow-[0_-10px_8px_rgba(0,0,0,0.04)] fixed bottom-0 w-full py-6 bg-white dark:bg-gray sm:!hidden"
          >
            <StyledTab label="Wonda" />
            <StyledTab label="Robots" />
            <StyledTab label="Reservations" />
            <StyledTab label="Workshops" />
          </StyledTabs>
        </section>
      </ThemeProvider>
    </>
  );
};

export default Home;