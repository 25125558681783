import { ListItem, ListPlaceholder } from './DashboardList'
import useAxios from "../utils/useAxios";
import { ListSpinner } from './LoadingSpinner';
import { useQuery } from '@tanstack/react-query';

async function fetchOrientations(api) {
  try {
    const {data:response} = await api.get(`/orientations/`)
    if(response) {
      return response
    }
  } catch (error) {
    console.log(error)
  }
}

function Orientations() {
  const api = useAxios()
  const { data, status } = useQuery(['orientations'], async () => fetchOrientations(api))
  if(status === 'loading') return <ListSpinner />
  if(status === 'error') return <p>error!</p>
  if(data.length === 0) return <ListPlaceholder text="No any orientations available. Please try again later." />
  if(data) return (
    data.map(obj => <ListItem key={obj.id} title={obj.title} link={obj.wonda_link} />)
  )
}

function OrientationList() {
  return <Orientations />
}


export default OrientationList;