import { useQuery } from '@tanstack/react-query';
import { ListItem, ListPlaceholder } from './DashboardList';
import { Fragment, useState, useContext } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import useAxios from "../utils/useAxios";
import AuthContext from "../context/AuthContext";
import { ListSpinner } from './LoadingSpinner';


function DialogActivate({ isOpen, closeModal, robots, api }) {
  const { user } = useContext(AuthContext)
  async function handleSubmit(e) {
    e.preventDefault()
    const code = e.target.code.value;
    const robot = robots.filter(r => r.activation_code === code)
    if (robot.length > 0) {
      // Fetch the current user data
      const { data: currentUser } = await api.get(`/users/${user.user_id}`);
      // Add the new robot id to the existing list
      const updatedAccessList = [...currentUser.has_access, robot[0].id];
      // Update the user data
      await api.put(`/users/${user.user_id}`, {
        id: user.user_id,
        email: user.email,
        has_access: updatedAccessList
      })
        .then(
          alert(`${robot[0].title} has been activated`),
        )
    }
    else { alert("robot not found") }
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#101010] bg-opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-sm transform overflow-hidden rounded-2xl bg-white dark:bg-gray p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg text-center font-medium leading-6 text-black dark:text-primary2"
                  >
                    Activate robot or equipment
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-black2 text-center dark:text-light dark:text-opacity-50">
                      Please enter your activation code
                    </p>
                  </div>

                  <div className="mt-4 text-center">
                    <form type="POST" onSubmit={handleSubmit}>
                      <input id="code" name='code' type="text" className=' w-40 text-sm font-medium text-center rounded-md mr-2 dark:text-light dark:focus:border-primary2 dark:focus:ring-transparent dark:bg-gray dark:focus:bg-gray-focus appearance-none rounded-b-md border px-3 py-2 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm'></input>
                      <button
                        type="submit"
                        className="inline-flex justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white dark:bg-gray dark:active: dark:text-light dark:border-primary2 dark:hover:bg-gray-focus dark:focus:shadow-none dark:focus:ring-offset-0 dark:focus:ring-primary2 dark:focus:ring-opacity-50 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        onClick={closeModal}
                      >
                        Submit
                      </button>
                    </form>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

async function fetchRobots(api) {
  try {
    const {data:response} = await api.get(`/robots-user/`)
    if(response) {
      return response
    }
  } catch (error) {
    console.log(error)
  }
}

function Robots () {
  const api = useAxios()
  let [isOpen, setIsOpen] = useState(false)

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  const { data, status } = useQuery(['robots'], async () => fetchRobots(api))
  if(status === 'loading') return <ListSpinner />
  if(status === 'error') return <p>error!</p>
  if(data.unactive.length === 0 && data.active.length === 0) return <ListPlaceholder text="No any robots available. Please try again later." />
  if(data.reservations_disabled) return <ListPlaceholder text={data.reservations_disabled_text} />
  if(data) return (
    <>
    {<DialogActivate isOpen={isOpen} closeModal={closeModal} openModal={openModal} robots={data.unactive} api={api}/>}
    {data.active.map(obj => <ListItem key={obj.id} title={obj.title} disabled={false} link={`/robot/${obj.id}`}/>)}
    {data.unactive.map(obj => <ListItem key={obj.id} title={obj.title} disabled={true} onClick={(e) => e.preventDefault()} openModal={openModal}/>)}
    </>
  )
}

function RobotList() {
  return <Robots />
}

export default RobotList;