import { useState, useContext, useEffect } from "react";
import TextField from "@mui/material/TextField";
import { LocalizationProvider, StaticDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useHistory } from "react-router-dom";
import dayjs from "dayjs";
import TimePicker from "../components/TimePicker";
import AuthContext from "../context/AuthContext";
import useAxios from "../utils/useAxios";
import { Helmet } from "react-helmet-async"
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { makeStyles } from "@mui/styles";
import { Spinner } from "../components/LoadingSpinner";

async function fetchSettings(api) {
  try {
    const { data: response } = await api.get(`/settings/`);
    if (response) {
      return response;
    }
  } catch (error) {
    console.error(error);
  }
}

async function fetchRobotDetails(api, robotId) {
  try {
    const { data: response } = await api.get(`/robots/${robotId}`);
    if (response) {
      return response;
    }
  } catch (error) {
    console.log(error);
  }
}

const useStyles = makeStyles({
  root: {
    "& .MuiDialogActions-root": {
      display: "none",
    },
    "& .MuiPickersToolbar-root": {
      display: "none",
    },
  },
});

// Wait until settings have been fetched to calculate the next allowed day.
const getNextAllowedDay = (allowedDays) => {
  let nextDay = dayjs();
  while (!allowedDays.includes(nextDay.day())) {
    nextDay = nextDay.add(1, 'day');
  }
  return nextDay;
}

export default function Robot() {
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const api = useAxios();
  const router = useHistory();
  const { robotId } = useParams();
  const { data: settings, isLoading: isLoadingSettings } = useQuery(["settings"], async () => fetchSettings(api));
  const { data: robotDetails, isLoading: isLoadingRobotDetails } = useQuery(['robotDetails', robotId], async () => fetchRobotDetails(api, robotId));
  const title = isLoadingRobotDetails ? "Loading..." : robotDetails.title;
  const timezoneIdentifier = "Europe/Helsinki"

  const classes = useStyles();

  const { user } = useContext(AuthContext);

  const [day, setDay] = useState(null);
  const [start, setStart] = useState(null);
  const [end, setEnd] = useState(null);

  let selected = 
    `${dayjs(day).format("dddd, DD MMM")}`;

  if(start){ selected += `${dayjs(start).format(", HH:mm")} - ${dayjs(end).format("HH:mm")}` }


  useEffect(() => {
    if (!isLoadingSettings && settings) {
      setDay(getNextAllowedDay(settings.allowed_week_days));
    }
  }, [isLoadingSettings, settings]);

  if (isLoadingSettings) {
    return (
      <>
        <div className="justify-center flex space-x-5 p-6 w-full">
          <div>
            <h4 className="sm:w-[30rem] text-3xl font-extrabold text-primary dark:text-primary2">
              Make a Reservation for ...
            </h4>
            <div className="sm:flex sm:space-x-5 mt-5 w-[30rem]">
              <div className="relative h-[20rem] w-[20rem] sm:h-[28rem] bg-white rounded-md dark:bg-gray dark:text-light">
<Spinner className={'absolute top-0 bottom-0'} />
              </div>
              {/* TIME PICKER */}
              {/* <TimePicker 
                robotId={robotId} 
                setStart={setStart} 
                setEnd={setEnd} 
                date={day}
                startHour={9}
                endHour={17}
                isLoadingSettings={true}
                slotDuration={1}
            /> */}
            </div>
            <div className="mt-5 hidden sm:block">
              <p className=" font-extrabold text-2xl text-primary dark:text-primary2">
                Loading...
              </p>
              <p className="text-2xl font-extrabold text-primary dark:text-primary2">{selected}</p>
            </div>
            <div className="flex space-x-4">
              <a
                href="/"
                className="mt-5 group relative w-full flex justify-center py-2 px-4 border-transparent text-sm font-medium rounded-md border border-primary text-primary bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-700dark:bg-gray dark:active: dark:text-light dark:border-primary2 dark:border-opacity-50 dark:hover:bg-gray dark:focus:shadow-none dark:focus:ring-offset-0 dark:focus:ring-primary2 dark:focus:ring-opacity-50"
              >
                Cancel
              </a>
              <button
                className="mt-5 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary focus:outline-none focus:ring-2 focus:ring-indigo-500 dark:bg-gray dark:active: dark:text-light dark:border-primary2 dark:hover:bg-gray-focus dark:focus:shadow-none dark:focus:ring-offset-0 dark:focus:ring-primary2 dark:focus:ring-opacity-50 focus:ring-offset-2"
                onClick={HandleReservation}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }

  async function HandleReservation() {
    const starts_at = dayjs.tz(dayjs(day).format("YYYY-MM-DD") + " " + dayjs(start).format("HH:mm"), timezoneIdentifier);
    const ends_at = dayjs.tz(dayjs(day).format("YYYY-MM-DD") + " " + dayjs(end).format("HH:mm"), timezoneIdentifier);
    if (
      window.confirm(
        `You're placing new reservation for ${title} at \n${selected}. \n\nAre you sure?`
      )
    ) {
      try {
        const res = await api.post(`reservations/`, {
          starts_at:starts_at,
          ends_at:ends_at,
          date:dayjs(day).set('hour', 0).set('minute', 0).set('second', 0),
          robot: robotId,
          created_by:user.user_id
        })
  
        if(res.data.email) {
          router.push('/login')
        }
      } catch (error) {
        console.log(error)
      }
      alert("reservation placed");
      router.push("/");
    }
  };

  return (
    <>
      <Helmet><title>Make a Reservation for {title} | Avoin Robolab</title></Helmet>
      <div className="justify-center flex space-x-5 p-6 min-w-full">
        <div>
          <h4 className="sm:w-[30rem] text-3xl font-extrabold text-primary dark:text-primary2">
            Make a Reservation for {title}
          </h4>
          <div className="sm:flex sm:space-x-5 mt-5 sm:w-[30rem]">
            <div className="h-[20rem] sm:h-[28rem] bg-white rounded-md dark:bg-gray dark:text-light">
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <StaticDatePicker
                  displayStaticWrapperAs="mobile"
                  className={classes.root}
                  disablePast={true}
                  views={["month", "day"]}
                  value={day}
                  onChange={(newValue) => {
                    setDay(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  shouldDisableDate={(date) => {
                    const currentDate = dayjs();
                    const isToday =
                      date.date() === currentDate.date() &&
                      date.month() === currentDate.month() &&
                      date.year() === currentDate.year();
                
                    // Check if the current day number is in the allowed_week_days array
                    const isDisabledDay = !settings?.allowed_week_days?.includes(date.day());

                    return isToday || isDisabledDay;
                  }}
                />
                <div className="px-5 mt-6 hidden sm:block">
                  <div className="flex">
                    <div className="w-5 h-5 rounded-full bg-primary dark:bg-primary2 mr-2"></div>
                    <p className=" leading-5">selected</p>
                  </div>
                  <div className="flex mt-2">
                    <div className="w-5 h-5 rounded-full border border-black dark:border-light mr-2"></div>
                    <p className=" leading-5">current day</p>
                  </div>
                  <div className="flex mt-2">
                    <div className="w-5 h-5 rounded-full mr-2 bg-disabled dark:bg-light-gray"></div>
                    <p className=" leading-5">not available</p>
                  </div>
                </div>
              </LocalizationProvider>
            </div>
            {/* TIME PICKER */}
            <TimePicker 
              robotId={robotId} 
              setStart={setStart} 
              setEnd={setEnd} 
              date={day}
              startHour={settings && settings.time_start}
              endHour={settings && settings.time_end}
              isLoadingSettings={isLoadingSettings}
              slotDuration={settings && settings.timeslot_duration}
            />
          </div>
          <div className="mt-5 hidden sm:block">
            <p className=" font-extrabold text-2xl text-primary dark:text-primary2">
              Date selected:
            </p>
            <p className="text-2xl font-extrabold text-primary dark:text-primary2">{selected}</p>
          </div>
          <div className="flex space-x-4">
            <a
              href="/"
              className="mt-5 group relative w-full flex justify-center py-2 px-4 border-transparent text-sm font-medium rounded-md border border-primary text-primary text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-700dark:bg-gray dark:active: dark:text-light dark:border-primary2 dark:border-opacity-50 dark:hover:bg-gray dark:focus:shadow-none dark:focus:ring-offset-0 dark:focus:ring-primary2 dark:focus:ring-opacity-50"
            >
              Cancel
            </a>
            <button
              className="mt-5 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-primary focus:outline-none focus:ring-2 focus:ring-indigo-500 dark:bg-gray dark:active: dark:text-light dark:border-primary2 dark:hover:bg-gray-focus dark:focus:shadow-none dark:focus:ring-offset-0 dark:focus:ring-primary2 dark:focus:ring-opacity-50 focus:ring-offset-2"
              onClick={HandleReservation}
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </>
  );
};