import { ListItem, ListPlaceholder } from './DashboardList'
import useAxios from "../utils/useAxios";
import dayjs from "dayjs";
import { ListSpinner } from './LoadingSpinner';
import { useQuery } from '@tanstack/react-query';
import { Dialog, Transition } from '@headlessui/react'
import AuthContext from "../context/AuthContext";
import { Fragment, useContext, useState } from 'react'

function DialogActivate({ isOpen, closeModal, data, api }) {
  const { user } = useContext(AuthContext)
  async function handleSubmit(e) {
    e.preventDefault()
    if (data) {
      await api.put(`/workshops/${data.id}`, {...data, enrolled_users: [...data.enrolled_users, user.user_id] })
      .then(
        alert(`You have been enrolled to ${data.title}`),
      )
    }
    else {alert("something went wrong")}
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-[#101010] bg-opacity-50" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-xl transform overflow-hidden rounded-2xl bg-white dark:bg-gray p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg text-center font-medium leading-6 text-black dark:text-primary2"
                  >
                    {data.title}
                  </Dialog.Title>
                  <h4
                    className="text-md mt-0 text-center font-small leading-6 text-black dark:text-primary2"
                  >{dayjs(data.starts_at).format('dddd DD.MM.')} from {dayjs(data.starts_at).format("HH:mm")} to {dayjs(data.ends_at).format("HH:mm")}</h4>
                  <div className="mt-2">
                    <p className="text-sm text-black2 whitespace-pre-line dark:text-light dark:text-opacity-50">
                      {data.description}
                    </p>
                  </div>
                  {data.enrolled_users?.indexOf(user.user_id) > -1 ? 
                    <><h4
                    className="text-md text-center mt-2 font-small leading-6 text-success"
                  >
                    You have enrolled to this workshop
                  <small className="text-black2 dark:text-light dark:text-opacity-40"><br />If you wish to cancel your enrollment, please contact <a href="mailto:riku.manninen@hamk.fi">riku.manninen@hamk.fi</a></small> 
                  </h4>
                  </>
                  :
                  data.total_seats - data.enrolled_users?.length <= 0 ?
                  <h4
                    className="text-md text-center mt-2 font-small leading-6 text-danger"
                  >
                    All seats full
                  </h4>
                : 
                <>
                <h4
                  className="text-md text-center mt-2 font-small leading-6 text-success"
                >
                  {data.total_seats - data.enrolled_users?.length} seats left
                </h4>
                <div className="mt-4 text-center">
                <form type="POST" onSubmit={handleSubmit}>
                  <button
                    type="submit"
                    className="inline-flex justify-center rounded-md border border-transparent bg-primary px-4 py-2 text-sm font-medium text-white dark:bg-gray dark:active: dark:text-light dark:border-primary2 dark:hover:bg-gray-focus dark:focus:shadow-none dark:focus:ring-offset-0 dark:focus:ring-primary2 dark:focus:ring-opacity-50 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                    onClick={closeModal}
                  >
                    Enroll
                  </button>
                </form>
              </div>
              </>
                }
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  )
}

async function fetchWorkshops(api) {
  try {
    const {data:response} = await api.get(`/workshops/`)
    if(response) {
      return response
    }
  } catch (error) {
    console.log(error)
  }
}

function CreateTitle(start, end, title) {
  return `${title} | ${dayjs(start).format("dddd DD.MM.")}`
}

function Workshops() {
  const api = useAxios()
  const [isOpen, setIsOpen] = useState(false)
  const [selectedItem, setSelectedItem] = useState({})

  function closeModal() {
    setIsOpen(false)
  }

  function openModal() {
    setIsOpen(true)
  }

  const { data, status } = useQuery(['workshops'], async () => fetchWorkshops(api))
  const activeWorkshops = data?.filter(obj => obj.is_active);
  if(status === 'loading') return <ListSpinner />
  if(status === 'error') return <p>error!</p>
  if(data.length === 0) return <ListPlaceholder text="No workshops available at this time." />
  if(activeWorkshops.length === 0) return <ListPlaceholder text="There's currently have no active workshops. Once a workshop becomes active, it will be displayed here." />
  return (
    <>
      {activeWorkshops.map((obj, i) => 
        <ListItem key={obj.id} title={CreateTitle(obj.starts_at, obj.ends_at, obj.title)} onClick={() => {setSelectedItem(activeWorkshops[i]); openModal();}} />
      )}
      <DialogActivate isOpen={isOpen} closeModal={closeModal} openModal={openModal} data={selectedItem} api={api}/>
    </>
  )
}

function WorkshopList() {
  return <Workshops />
}


export default WorkshopList;