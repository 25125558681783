import { createContext, useState, useEffect } from "react";
import jwt_decode from "jwt-decode";
import { useHistory } from "react-router-dom";
import { toast } from 'react-toastify';
import axios from "axios";

const AuthContext = createContext();

export default AuthContext;

export const AuthProvider = ({ children }) => {
  const [authTokens, setAuthTokens] = useState(() =>
    localStorage.getItem("authTokens")
      ? JSON.parse(localStorage.getItem("authTokens"))
      : null
  );
  const [user, setUser] = useState(() =>
    localStorage.getItem("authTokens")
      ? jwt_decode(localStorage.getItem("authTokens"))
      : null
  );
  const [loading, setLoading] = useState(true);

  const history = useHistory();

  const loginUser = async (email, password) => {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/token/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email,
        password
      })
    });
    const data = await response.json();
    if (response.status === 200) {
      setAuthTokens(data);
      setUser(jwt_decode(data.access));
      localStorage.setItem("authTokens", JSON.stringify(data));
      history.push("/");
      toast.success("You have been logged in successfully.");
    } 
    else {
      toast.error(`Login failed: ${data.detail}`);
    }
  };
  
  const registerUser = async (email, password, password2) => {
    const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/api/register/`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        email,
        password,
        password2
      })
    });
    const data = await response.json();
    if (response.status === 201) {
      history.push("/login");
      toast.success("Your account has been successfully created.");
    } else {
      console.log(data)
      toast.error(`Registeration failed: ${data.detail}`);
    }
  };

  const logoutUser = () => {
    setAuthTokens(null);
    setUser(null);
    localStorage.removeItem("authTokens");
    history.push("/");
    toast.success("You have been successfully logged out.");
  };

  const refreshToken = async () => {
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}/api/token/refresh/`, {
      refresh: authTokens.refresh
    });

    localStorage.setItem("authTokens", JSON.stringify(response.data));
    console.log('response: ', jwt_decode(response.data.access))
    setAuthTokens(response.data);
    setUser(jwt_decode(response.data.access));
  }

  const contextData = {
    user,
    setUser,
    authTokens,
    setAuthTokens,
    registerUser,
    loginUser,
    logoutUser,
    refreshToken
  };

  useEffect(() => {
    if (authTokens) {
      setUser(jwt_decode(authTokens.access));
    }
    setLoading(false);
  }, [authTokens, loading]);

  return (
    <AuthContext.Provider value={contextData}>
      {loading ? null : children}
    </AuthContext.Provider>
  );
};