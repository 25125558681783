// StyledComponents.js
import { styled } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';

export const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))(({ theme }) => ({
  '&& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    transition: 'all 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;'
  },
  '&& .MuiTabs-indicatorSpan': {
    maxWidth: 40,
    width: '100%',
    backgroundColor: theme.palette.mode === 'dark' ? '#918abf' : '#282666',
  },
  '&& .MuiTabs-flexContainer': {
    backgroundColor: theme.palette.mode === 'dark' ? '#262626' : '#fff',
  }
}));

export const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.pxToRem(15),
    marginRight: theme.spacing(1),
    color: theme.palette.mode === 'dark' ? '#707070' : '#28266680', // Change color based on theme mode
    backgroundColor: theme.palette.mode === 'dark' ? '#262626' : '#fff',
    '&&.Mui-selected': {
      color: theme.palette.mode === 'dark' ? '#918abf' : '#282666',
      backgroundColor: theme.palette.mode === 'dark' ? '#262626' : '#fff',
    },
  }),
);