import { useEffect, useState } from "react";
import { useQuery } from '@tanstack/react-query';
import useAxios from "../utils/useAxios";
import dayjs from 'dayjs';
import { Spinner } from "./LoadingSpinner";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import isoWeek from 'dayjs/plugin/isoWeek';
import 'dayjs/locale/en-gb';
import localeData from 'dayjs/plugin/localeData';

dayjs.extend(isoWeek);
dayjs.extend(localeData);
dayjs.locale('en-GB');

async function fetchTimes(robotId, api, date) {
  let dateUrl = dayjs(date).format('YYYYMMDD')
  try {
    const {data:response} = await api.get(`/robots/${robotId}/${dateUrl}`)
    if(response) {
      return response
    }
  } catch (error) {
    console.log(error)
  }
}

const TimePicker = ({ robotId, setStart, setEnd, date, startHour, endHour, isLoadingSettings }) => {
  dayjs.extend(utc);
  dayjs.extend(timezone);

  const api = useAxios()
  const timezoneIdentifier = "Europe/Helsinki"
  const start = parseInt(startHour)
  const end = parseInt(endHour)

  const [timeState, setTimeState] = useState(
    new Array(8).fill({ checked: false, booked: true })
  );

  const { isLoading, refetch } = useQuery(
    [date], // Change this line to use the date as the query key
    async () => fetchTimes(robotId, api, date),
    {
      onSuccess: (data) => {
        setTimeState([]);
        data.times.forEach((v, i) => {
          setTimeState((timeState) => [
            ...timeState,
            { checked: false, booked: v },
          ]);
        });
      },
    }
  );

  const timeIsNotSelected = Object.values(timeState).every(
    value => value.checked === false
  );

  useEffect(() => {
    refetch()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date])

  useEffect(() => {
    if(timeIsNotSelected){
      setEnd(null)
      setStart(null)
    }
  }, [setStart, setEnd, timeIsNotSelected]);

  const updateCheckedState = (position, timeState) =>
  timeState.map((state, index) =>
    index === position ? { ...state, checked: !state.checked } : state
  );

  const isBookedBetween = (start, end, timeState) => {
    const booked = timeState.findIndex((e) => e.booked === true);
    return booked > start && booked < end;
  };

  const handleOnChange = (position) => {
    const updatedCheckedState = updateCheckedState(position, timeState);
    const start = updatedCheckedState.findIndex((e) => e.checked === true);
    const end = updatedCheckedState.findLastIndex((e) => e.checked === true);

    if (isBookedBetween(start, end, timeState)) {
      alert("Cannot do that");
      return;
    }

    updatedCheckedState.fill({ checked: true, booked: false }, start, end);
    setTimeState(updatedCheckedState);

    const startDate = dayjs.tz(dayjs().hour(start + 9).minute(0).second(0), timezoneIdentifier).toDate();
    const endDate = dayjs.tz(dayjs().hour(end + 10).minute(0).second(0), timezoneIdentifier).toDate();

    setStart(start === -1 ? null : startDate);
    setEnd(end === -1 ? null : endDate);
  };

  return (
    <div className="space-x-0 w-full sm:w-32">
      {timeState.map((state, i) => (
        <div key={i} className="relative">
          <input
            checked={state.checked}
            type={"checkbox"}
            id={i}
            disabled={state.booked && "disabled"}
            className="hidden peer"
            onChange={() => handleOnChange(i)}
          ></input>
          <label
            htmlFor={i}
            className="border border-transparent dark:bg-gray dark:text-light dark:hover:bg-gray-focus dark:peer-checked:border-primary2 dark:peer-checked:bg-gray-focus bg-white text-center block rounded-md mt-2 p-2 hover:cursor-pointer outline-2 outline-transparent outline-offset-1 transition-all hover:border-primary peer-checked:bg-primary peer-checked:text-white peer-disabled:cursor-not-allowed peer-disabled:opacity-40 peer-disabled:text-disabled2 peer-disabled:border-transparent dark:peer-disabled:hover:bg-gray dark:hover:border-primary2 dark:hover:border-opacity-40"
          >
            {isLoading ? 'ㅤ' : `${i + start}.00 - ${i + start + 1 }.00`}
          </label>
          {isLoading && <Spinner className="mt-1.5"/>}
        </div>
      ))}
    </div>
  )
}

export default TimePicker